import * as React from "react"
import Seo from "../../components/seo"
import { RichText } from 'prismic-reactjs'
import { Link, graphql } from "gatsby"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Copyright from "../../components/copyright"
import { GatsbyImage } from "gatsby-plugin-image"

const ServicesPage = ({data}) => {
  const entry = data.prismicServicesPage
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname="/services/" />
      <Header />
      <div className="w-full pt-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex gap-12 justify-between">
            <div className="w-full sm:w-8/12">
              <div className="font-ubuntu font-bold text-red text-4xl sm:text-5xl leading-tight mb-6 sm:mb-12">
                <h1>{entry.data.heading.text}</h1>
              </div>
            </div>
            <div className="w-full sm:w-4/12">
              &nbsp;
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pb-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
            {entry.data.entries.map((entry, i) => (
              <div className="group" key={`entry_${i}`}>
                <Link to={entry.link.url}>
                  <div className="relative">
                    <div className={`absolute z-10 right-0 px-3 pb-3 center h-full text-white bg-${entry.colour} opacity-1`}>
                      <div className="flex h-full items-end">
                        <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-12 w-12" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                      </div>
                    </div>
                    <GatsbyImage image={entry.image.gatsbyImageData} alt={entry.heading1.text} className="h-64" style={{ width: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
                  </div>
                  <div className="font-ubuntu text-black text-2xl leading-tight mt-6 mb-3">
                    <h2 className="border-b-2 border-white group-hover:border-b-2 group-hover:border-black inline">{entry.heading1.text}</h2>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full pb-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex gap-12 items-center justify-between">
            <div className="w-full sm:w-6/12 mb-12 sm:mb-0">
              <GatsbyImage image={entry.data.image.gatsbyImageData} alt={entry.data.heading.text} className="h-96" style={{ width: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
            </div>
            <div className="w-full sm:w-6/12">
              <div className="font-ubuntu prose prose-xl leading-normal">
                <h2>{entry.data.intro_heading.text}</h2>
                {RichText.render(entry.data.text.richText)}
              </div>
              <div className="font-ubuntu text-2xl text-red border-2 border-red py-2 px-4 mt-12 inline-block">
                <Link to="/contact/">Make enquiry <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Copyright />
    </>
  )
}

export const query = graphql`
query {
  prismicServicesPage {
    data {
      text {
        richText
      }
      page_title
      meta_description
      heading {
        text
      }
      intro_heading {
        text
      }
      image {
        gatsbyImageData(width: 640, height: 640)
      }
      entries {
        colour
        heading1 {
          text
        }
        image {
          gatsbyImageData(width: 640, height: 640)
        }
        link {
          url
        }
      }
    }
  }
}
`

export default ServicesPage
